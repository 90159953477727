<template>
    <div
        v-if="
            currentPosition.latitude &&
            currentPosition.longitude &&
            geolocation.lat &&
            geolocation.lng &&
            aroundMeCookie
        "
        class="relative ml-4 inline-flex flex-shrink-0 items-center gap-x-1.5 rounded-full bg-black/50 px-4 py-2 text-xs font-medium text-[#E0E0E0]"
    >
        <span class="font-Poppins text-xs text-white">
            {{
                calculateDistance(
                    {
                        lat: geolocation.lat,
                        lng: geolocation.lng,
                    },
                    {
                        lat: currentPosition.latitude,
                        lng: currentPosition.longitude,
                    },
                )
            }}
        </span>
    </div>
</template>

<script setup>
const { calculateDistance } = useDistanceCalculator();
const currentPosition = useStore().currentPosition;
const aroundMeCookie = useCookie('aroundMe');

defineProps({
    geolocation: {
        type: Object,
        required: true,
        NonNullable: false,
    },
});
</script>

<style></style>
