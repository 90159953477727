export const useDistanceCalculator = () => {
  /**
   * Calculate the distance between two points using the Haversine formula.
   * 
   * @param {Object} start - Starting point { lat: number, lng: number }
   * @param {Object} end - Ending point { lat: number, lng: number }
   * @returns {string} - Distance in kilometers
   */
  const calculateDistance = (start, end, format = true) => {
    const toRadians = (degree) => degree * (Math.PI / 180);

    const R = 6371; // Radius of the Earth in kilometers

    const lat1 = toRadians(start.lat);
    const lng1 = toRadians(start.lng);
    const lat2 = toRadians(end.lat);
    const lng2 = toRadians(end.lng);

    const dLat = lat2 - lat1;
    const dLng = lng2 - lng1;

    const a = Math.sin(dLat / 2) ** 2 +
      Math.cos(lat1) * Math.cos(lat2) *
      Math.sin(dLng / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  /**
   * Format the distance to display the nearest whole kilometer (no decimals).
   * 
   * @param {number} distance - Distance in kilometers (as a float)
   * @returns {string} - Formatted distance string with "À <distance> Km"
   */
  const formatDistance = (distance) => {
    return `à ${Math.ceil(distance)} Km`;
  };

  return {
    calculateDistance: (start, end, format = true) => {
      const distance = calculateDistance(start, end);
      return format ? formatDistance(distance) : distance;
    }
  };
};
